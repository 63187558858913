/**
 * @file Authentication
 */

import React from 'react';
import localStorageMemory from 'localstorage-memory';
import Confirm from '../Confirm';
import Login from '../Login';

class Authentication extends React.Component {
  constructor(props) {
    super(props);
    this.identity = props.identity;
    this.token = localStorageMemory.getItem('token');
  }

  render() {
    let content;

    if (this.token !== null) {
      content = <Confirm identity={this.identity} />;
    } else {
      content = <Login identity={this.identity} />;
    }

    return (
      <div>
        <div className="grid-container">
          <div className="grid-x">
            <div className="cell small-8 small-offset-2 large-4 large-offset-4">
              {content}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Authentication;
