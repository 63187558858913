/**
 * @file Index Page
 */

import React from 'react';
import { globalHistory } from '@reach/router';
import { useIdentityContext } from 'react-netlify-identity-widget';
import localStorageMemory from 'localstorage-memory';
import Authentication from '../components/Authentication';

const IndexPage = () => {
  const identity = useIdentityContext();

  if (globalHistory.location.hash && globalHistory.location.hash.indexOf('#invite_token=') === 0) {
    const token = globalHistory.location.hash.replace('#invite_token=', '');
    localStorageMemory.setItem('token', token);
  }

  return (
    <Authentication identity={identity} />
  );
};

export default IndexPage;
