/**
 * @file Confirm
 */

import React from 'react';
import localStorageMemory from 'localstorage-memory';
import '../../scss/app.scss';

class Confirm extends React.Component {
  constructor(props) {
    super(props);
    this.identity = props.identity;

    this.state = {
      buttonClass: null,
      value: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({
      value: event.target.value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    const token = localStorageMemory.getItem('token');

    const {
      value,
    } = this.state;

    this.setState({
      buttonClass: 'loading',
    });

    /* eslint no-underscore-dangle: ["error", { "allow": ["_goTrueInstance"] }] */
    this.identity._goTrueInstance.acceptInvite(
      token,
      value,
      true,
    )
      .then(() => {
        localStorageMemory.setItem('token', '');

        setTimeout(() => {
          this.setState({
            buttonClass: 'success',
          });

          setTimeout(() => {
            document.location.href = '/app/dashboard';
          }, 2000);
        }, 2000);
      })
      .catch(() => {});
  }

  render() {
    const {
      buttonClass,
    } = this.state;

    return (
      <form
        className="identity-form"
        onSubmit={this.handleSubmit}
        action="/"
      >
        <div className="logo" />
        <span className="label label--alert text-center">Please enter a password for your account</span>
        <input
          name="password"
          type="password"
          placeholder="Password"
          onChange={this.handleChange}
          required
        />
        <button
          type="submit"
          className={`button button--expanded button--primary ${buttonClass ? `button--${buttonClass}` : null}`}
        >
          {buttonClass === 'success' ? 'Account Confirmed!' : 'Set Up Account' }
        </button>

      </form>
    );
  }
}

export default Confirm;
